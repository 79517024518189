import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import { Typography, Grid, TextField, InputLabel, Select, FormControl, MenuItem, withStyles } from "@material-ui/core";
import {
  NewDataTable,
  KeyValueTable,
  CardContainer,
  Button,
  CustomInput,
  GridItem, 
} from 'components';
import DataContext from 'context/Data'
import utils from 'utils/utils'
import _ from 'lodash';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  customInputContainer: {
    margin: '0 !important',
    padding: 0
  },
  customInputNoMargin: {
    margin: '0 !important',
    padding: 0
  },
  menuItemText: {
    whiteSpace: 'break-spaces',
    wordWrap: 'break-word',
    // ['@media (min-width:780px)']:
    [theme.breakpoints.down('md')]: {
      maxWidth: "400px",
    },
    [theme.breakpoints.between('md', 'lg')]: {
      maxWidth: "600px",
    },
    [theme.breakpoints.up('lg')]: {
      // maxWidth: "700px",
    },
  },
});

class Cycle extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    // search cycle locaiton
    this.location_input = null;
    // verify SKU to add
    this.sku_input = null;
    this.quantity_input = null;

    this.location_id = '';
    this.location_name = '';

    this.temp_data = {
      barcode_map: {},
    };

    this.state = {
      loading: false,
      reason: '', // cycle reason
      notes: '', // cycle notes
      tableData: null, // location items table data
      topIndex: 0,
    };

    document.title = "Cycle";
  }

  render() {
    const { classes } = this.props;
    const { tableData, reason, notes } = this.state;

    return (
      <Grid container spacing={2}>
        {this.state.loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}
      
        <GridItem xs={12} sm={6}>
          <CardContainer>
            <form onSubmit={this.searchLocationInventory}>
              <CustomInput
                labelText='Location'
                formControlProps={{
                    fullWidth: true,
                    required: true,
                    className: classes.customInput,
                }}
                labelProps={{
                    shrink: true,
                }}
                inputProps={{
                    defaultValue: '',
                    inputRef: elem => this.location_input = elem
                }}
              />
              <Button type='submit' className={classes.button}>Search</Button>
            </form>
          </CardContainer>
        </GridItem>

        <GridItem xs={12} sm={6}>
          {
            (this.location_id) && <CardContainer>
             <form onSubmit={this.submitCycle}>
               <FormControl required fullWidth className={classes.selectInput}>
                 <InputLabel shrink>Reason Code</InputLabel>
                 <Select
                   renderValue={(val)=>{
                     const keyMap = {
                       ClientRequest: 'Client Request (all requests except cycle/physical counts discrepancies)',
                       ShipmentError: 'Shipment Error (both Receiving and Outbound discrepancies that are not under our control, i.e. difference in dock receiving, incorrect tag by client, etc.)',
                       Supplies: 'Supplies',
                       Discrepancies: 'Discrepancies (including cycle/physical counts regardless who requested it)'
                     };
                     return (
                       <span className={classes.selectedValue}>{keyMap[val]}</span>
                     ); 
                   }}
                   value={reason}
                   onChange={(e)=>{this.setState({reason: e.target.value})}}
                 >
                   <MenuItem className={classes.menuItem} value="ClientRequest"><Typography className={classes.menuItemText}>Client Request (all requests except cycle/physical counts discrepancies)</Typography></MenuItem>
                   <MenuItem className={classes.menuItem} value="ShipmentError"><Typography className={classes.menuItemText}>Shipment Error (both Receiving and Outbound discrepancies that are not under our control, i.e. difference in dock receiving, incorrect tag by client, etc.)</Typography></MenuItem>
                   <MenuItem className={classes.menuItem} value="Supplies"><Typography className={classes.menuItemText}>Supplies</Typography></MenuItem>
                   <MenuItem className={classes.menuItem} value="Discrepancies"><Typography className={classes.menuItemText}>Discrepancies (including cycle/physical counts regardless who requested it)</Typography></MenuItem>
                 </Select> 
               </FormControl>
 
                 <CustomInput
                     labelText='Cycle Notes'
                     formControlProps={{
                         fullWidth: true,
                         className: classes.customInput,
                         required: true,
                     }}
                     labelProps={{shrink: true}}
                     inputProps={{
                         value: notes,
                         onChange: (e)=>{this.setState({notes:e.target.value})}
                     }}
                 />
               <Button type='submit' className={classes.button}>Submit Cycle</Button>
             </form>
           </CardContainer>
          }
        </GridItem>

        {this.renderLocationTable()}
      </Grid>
    );
  }

  // API call
  searchLocationInventory = (e) => {
    e.preventDefault();
    let location = this.location_input ? utils.formatString(this.location_input.value) : '';
    if (!location) {
      this.context.alert("Location is invalid");
      return;
    }

    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/cycle/${location}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    // reset location
    this.location_id = '';
    this.location_name = '';

    req.then(this.searchLocationInventorySuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
    this.setState({loading: true, tableData: null, reason: '', notes: ''})
  }
  searchLocationInventorySuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
     this.context.alert(resp.data.Error);
      return;
    }
    // Some clients, for now it's only 204, 
    this.temp_data.barcode_map = (!resp.data.barcode_map || resp.data.barcode_map.length == 0) ? {} : resp.data.barcode_map;

    let tableData = [];
    for (let location_item of resp.data.result) {
      if (!location_item.inventory_id || !location_item.item_id) continue; // skip dummy item
      location_item.quantity_input = 0;
      tableData.push(location_item);
    }

    // record locatin id and name to cycle
    this.location_name = resp.data.result[0].location;
    this.location_id = resp.data.result[0].location_id;

    this.setState({tableData: tableData});
  }
  verifySKU = (e) => {
    e.preventDefault();
    let sku = this.sku_input ? this.sku_input.value : '';
    sku = utils.formatString(sku);
    if (!sku) return;
    if (this.temp_data.barcode_map[sku]) sku = this.temp_data.barcode_map[sku];
    
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/cycle/${this.location_id}/${sku}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    req.then(this.verifySKUSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
    this.setState({loading: true});
  }
  verifySKUSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }

    if (resp.data.length == 0) {
      this.context.alert("This SKU doesn't exist in current customer product catalog.");
      return;
    }

    let newItems = Array.from(this.state.tableData);
    let sku = resp.data[0].sku; // may need to check item_id
    let matched_index = -1;
    for (let i = 0; i < newItems.length; i++) {
      if (sku == newItems[i]['sku']) { // match sku and UPC?
        matched_index = i;
        break;
      }
    }
  

    let topIndex = newItems.length;
    if (matched_index  >= 0) { // found item in the current location, change qty
      newItems[matched_index].quantity_input = 1 + (parseInt(newItems[matched_index].quantity_input) || 0);
      topIndex = matched_index;
    } else { // item not in the locaiton, add it to the begining
      // [Object.assign({quantity_input: 1}, resp.data[0])].concat(newItems);
      newItems.push(Object.assign({quantity_input: 1, quantity: 0}, resp.data[0]));
    }
    this.setState({tableData: newItems, topIndex});
  }
  submitCycle = (e) => {
    e.preventDefault();
    const {tableData, reason, notes} = this.state;
    
    let submitItems = [];
    let err = '';
    for (let item of tableData) {
      item.quantity_input = parseInt(item.quantity_input);
      if (!item.quantity_input || item.quantity_input == 0) continue; // skip if sku quantity is not changed 
      let qty_available = item.quantity - (item.allocated_quantity || 0);
      if ((item.quantity_input + qty_available) < 0) {
        err += `For SKU ${item.sku}, you can only cycle out ${item.quantity - (item.allocated_quantity || 0)}. ${item.allocated_quantity > 0 && `(${item.allocated_quantity} of this item allocated)`} \n`;
      }
      submitItems.push(item);
    }
    if (submitItems.length <= 0) err += 'No item change made.';
    if (err) {
      this.context.alert(err);
      return;
    }

    let data = {
      location_id: this.location_id,
      location_name: this.location_name,
      reason_code: reason,
      notes: notes,
      items: submitItems
    };

    this.submitCycleOnce(data);
  }
  submitCycleAJax = (data) => {
    this.setState({loading: true})
    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/cycle`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data,
    });

    req.then(this.submitCycleSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert, errorCallback: this.resetSubmitOnce}));
    this.setState({loading: true})
  }
  resetSubmitOnce = () => {this.submitCycleOnce = _.once(this.submitCycleAJax);} // reset the submit picking once after the ajax call returns
  submitCycleOnce = _.once(this.submitCycleAJax)
  submitCycleSuccess = (resp) => {
    this.setState({loading: false});
    this.resetSubmitOnce();
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    this.context.snackDisplay("Submit cycle success.");
    window.location.reload();
  }

  renderLocationTable = () => {
      const { classes } = this.props;
      const { tableData, topIndex } = this.state;
      if (!this.location_id) return null;

      let colSettings = [
          {
              key: 'sku',
              label: 'SKU',
          },
          {
              key: 'upc',
              label: 'UPC',
          },
          {
              key: 'quantity',
              label: 'Quantity',
          },
          {
            key: 'allocated_quantity',
            label: 'Allocatd',
          },
          {
            key: 'quantity_input',
            label: 'Quantity Input',
            render: (val, key, row, index) => {
              return (
                <TextField
                  value={val || ''}
                  onChange={(e)=>{
                    let new_val = e.target.value;
                    let newItems = Array.from(tableData);
                    if (new_val < (0 - row.quantity)) new_val = 0 - row.quantity;
                    newItems[index] = Object.assign(row, {quantity_input: new_val});
                    this.setState({tableData: newItems});
                  }} // check if this works
                  type="number"
                  inputProps={{min: (0 - row.quantity + (row.allocated_quantity || 0))}} // minimum quantity can cycle out
                />
              );
            }
          },
      ];

      return (
        <GridItem xs={12}>
          <CardContainer>
            <div className={this.props.classes.root}>
              <div className={classes.title} >
                <Typography variant="h6">
                  {this.location_name}
                </Typography>
                <form onSubmit={this.verifySKU}>
                <div>       
                    <CustomInput
                      labelText='SKU'
                      formControlProps={{
                          fullWidth: false,
                          required: true,
                          className: this.props.classes.customInput,
                      }}
                      labelProps={{
                          shrink: true,
                      }}
                      inputProps={{
                        defaultValue: '',
                        inputRef: (elem)=> {this.sku_input = elem},
                        style: {marginRight: '.5rem'}
                      }}
                    />
                    <Button type='submit' style={{marginTop: '0.75rem'}}>Add</Button>
                  </div>
                </form>
              </div>
              <NewDataTable
                rows={tableData}
                columns={colSettings}
                noPagination
              />
            </div>
          </CardContainer>
        </GridItem>
      );
  }
}
export default withStyles(styles)(Cycle);
